import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { FlagProvider, useUnleashContext } from "@unleash/nextjs";

import { Event } from "@chef/events";
import { convertToE164Format } from "@chef/utils/phone";
import {
  selectTrackingConsents,
  selectTrackingIsConfigured,
  useGetTrackingDestinationsQuery,
  useSendCustomerIOLinkIdMutation,
  useMeQuery,
  useUserInfoQuery,
  useLazyGetIntercomUserHashQuery,
} from "@chef/state-management";
import { AnalyticsContext } from "@chef/state-management/hooks";
import { useCookie, useEffectOnce } from "@chef/hooks";
import { BRAND_NAME, language } from "@chef/constants";
import { useMobileApp } from "@chef/feature-mobile-app";
import { convertCategoriesToIntegrations } from "./utils";

import { ConsentSettings } from "./ConsentSettings";

const WRITE_KEY = process.env["NX_SEGMENT_WRITE_KEY"] as string;

interface TrackingProviderProps {
  children: React.ReactNode;
}

interface InnerProps extends React.PropsWithChildren {
  userId: string | null;
}

const loadAnalytics = ({
  consents,
  appType,
}: {
  consents: Record<string, boolean>;
  appType: "web" | "mobile";
}) => {
  const a = AnalyticsBrowser.load(
    {
      writeKey: WRITE_KEY,
    },
    { integrations: consents, obfuscate: true },
  );

  // Censor ip address
  a.addSourceMiddleware(({ payload, next }) => {
    if (payload.obj.context) {
      payload.obj.context["ip"] = "0.0.0.0";
    }
    if (payload.obj.properties) {
      payload.obj.properties["brand"] = BRAND_NAME;
      payload.obj.properties["app_type"] = appType;
    }

    next(payload);
  });

  a.addSourceMiddleware(({ payload, next }) => {
    if (payload.obj.type === "track" && payload.obj.context?.page) {
      const currentUrl = new URL(window.location.href);
      payload.obj.context.page.path = currentUrl.pathname;
      payload.obj.context.page.search = currentUrl.search;
    }
    next(payload);
  });

  // Attach instance to window
  window.tracking = a;

  return a;
};

type ConsentStatus = "granted" | "denied";
type ConsentState = {
  "Ad Storage Consent State": ConsentStatus;
  "User Data Consent State": ConsentStatus;
  "Personalization Consent State": ConsentStatus;
  "Analytics Storage Consent State": ConsentStatus;
};

export const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const router = useRouter();
  const { data: user } = useMeQuery();
  const { isApp } = useMobileApp();

  const [getIntercomUserHash] = useLazyGetIntercomUserHashQuery();

  const { data: destinations } = useGetTrackingDestinationsQuery({
    writeKey: WRITE_KEY,
  });

  const { data: userInfo } = useUserInfoQuery(undefined, {
    skip: !user,
  });

  const { value: anonymousId } = useCookie("ajs_anonymous_id");

  const [analytics, setAnalytics] = useState<AnalyticsBrowser>();

  const consents = useSelector(selectTrackingConsents);
  const isConfigured = useSelector(selectTrackingIsConfigured);

  const queue = useRef<Event[]>([]);

  const agreementId = user?.agreementId;

  // Load analytics once consents are configured
  useEffect(() => {
    if (!isConfigured || !destinations || analytics) {
      return;
    }

    const integrations = convertCategoriesToIntegrations(
      consents,
      destinations,
    );

    const a = loadAnalytics({
      consents: integrations,
      appType: isApp ? "mobile" : "web",
    });

    setAnalytics(a);
  }, [analytics, consents, destinations, isApp, isConfigured]);

  // Initially used this approach https://segment.com/docs/connections/destinations/catalog/actions-google-analytics-4-web/#consent-mode
  // But in order to not add extra page event, we now store consent changes and send them on the next page event
  const updatedConsentForNextPageEvent = useRef<ConsentState | null>(null);

  useEffect(() => {
    const advertisingWasEnabled = !!consents["advertising"] || consents.All;
    const advertisingStatus = advertisingWasEnabled ? "granted" : "denied";

    const marketingWasEnabled = !!consents["marketing"] || consents.All;
    const marketingStatus = marketingWasEnabled ? "granted" : "denied";

    updatedConsentForNextPageEvent.current = {
      "Ad Storage Consent State": advertisingStatus,
      "User Data Consent State": marketingStatus,
      "Personalization Consent State": marketingStatus,
      "Analytics Storage Consent State": marketingStatus,
    };
  }, [consents]);

  useEffect(() => {
    if (!analytics) {
      return;
    }

    const handleRouteChange = (
      _url: string,
      { shallow }: { shallow: boolean },
    ) => {
      if (!shallow) {
        const urlObj = new URL(_url, window.location.origin);
        analytics.page({
          path: urlObj.pathname,
          search: urlObj.search,
          ...(updatedConsentForNextPageEvent.current
            ? updatedConsentForNextPageEvent.current
            : undefined),
        });
        updatedConsentForNextPageEvent.current = null;
      }
    };

    // Initial page event
    analytics.page();

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, analytics]);

  useEffectOnce(() => {
    queue.current.push({ type: "identify" });
  }, true);

  useEffect(() => {
    if (!analytics) {
      return;
    }

    analytics.addSourceMiddleware(({ payload, next }) => {
      if (payload.obj.context) {
        if (userInfo?.userInfo.email) {
          payload.obj.context["email"] = userInfo.userInfo.email;
        } else {
          delete payload.obj.context["email"];
        }

        if (userInfo?.userInfo.telephone) {
          payload.obj.context["phone"] = convertToE164Format(
            userInfo.userInfo.telephone,
            language,
          );
        } else {
          delete payload.obj.context["phone"];
        }
      }

      next(payload);
    });
  }, [analytics, userInfo]);

  useEffect(() => {
    if (!analytics) {
      return;
    }

    let interval: NodeJS.Timeout;

    (async () => {
      const { hash } = await getIntercomUserHash().unwrap();

      interval = setInterval(() => {
        while (queue.current.length > 0) {
          const event = queue.current.shift();

          if (event?.type === "track") {
            analytics.track(event.eventName, event.payload);
          }

          if (event?.type === "identify") {
            const integrations = hash
              ? { Intercom: { user_hash: hash } }
              : undefined;

            const opts = {
              appType: isApp ? "mobile" : "web",
              integrations,
            };

            analytics.identify(
              agreementId?.toString(),
              event.options ?? {},
              opts,
            );
          }
        }
      }, 500);
    })();

    return () => {
      clearInterval(interval);
    };
  }, [analytics, isApp, agreementId, getIntercomUserHash]);

  // Customer io email tracking
  const { link_id } = router.query;
  const [sendCustomerIOLinkIdMutation] = useSendCustomerIOLinkIdMutation();

  useEffectOnce(() => {
    sendCustomerIOLinkIdMutation(link_id as string).catch((err) => {
      console.error(err);
    });
  }, !!link_id);

  return (
    <FlagProvider
      config={{
        url: process.env.NEXT_PUBLIC_UNLEASH_PROXY_URL,
        clientKey: process.env.NEXT_PUBLIC_UNLEASH_PROXY_KEY,
        appName: process.env.APP_NAME,
        environment:
          process.env.APP_ENV === "production" ? "production" : "development",
        refreshInterval: 30,
      }}
    >
      <AnalyticsContext.Provider value={{ analytics, queue: queue.current }}>
        <ConsentSettings />
        <Inner userId={agreementId?.toString() || anonymousId}>
          {children}
        </Inner>
      </AnalyticsContext.Provider>
    </FlagProvider>
  );
};

const Inner = ({ children, userId }: InnerProps) => {
  const setCtx = useUnleashContext();

  useEffect(() => {
    if (!userId) {
      return;
    }

    setCtx({
      userId,
    });
  }, [setCtx, userId]);

  return children;
};
